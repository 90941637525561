import axios from "axios";
import React, { useState } from "react";
import {
  Heading,
  Text,
  Stack,
  Box,
  Input,
  Button,
  Radio,
  Flex,
  RadioGroup,
} from "@chakra-ui/react";
export default function Form() {
  // Input Change Handling
  const [isFranchise, setIsFranchise] = useState(null);

  const [inputs, setInputs] = useState({
    restaurantName: "",
    restaurantAddress: "",
    restaurantPhoneNumber: "",
    managerName: "",
    managerPhone: "",
    managerEmail: "",
    franchiseName: "",
    franchiseAddress: "",
    franchiseWebsite: "",
    franchisePhoneNumber: "",
    numLocations: "",
  });

  const handleOnChange = (event) => {
    event.persist();
    setInputs((prev) => ({
      ...prev,
      [event.target.id]: event.target.value,
    }));
  };

  // Server State Handling

  const handleOnSubmit = (event) => {
    event.preventDefault();
    console.log("data: ", inputs);
    axios({
      method: "POST",
      url: "https://formbold.com/s/3ZnAJ",
      data: inputs,
    })
      .then((r) => {
        console.log("hello");
      })
      .catch((r) => {
        console.log("error" + r);
      });
  };

  return (
    <>
      <Stack
        bg={"gray.50"}
        rounded={"xl"}
        //border={"2px solid #63b3ed"}
        p={{ base: 4, sm: 6, md: 8 }}
        spacing={{ base: 8 }}
        shadow={"xl"}
        maxW={{ lg: "lg" }}
      >
        <Stack spacing={4}>
          <Heading
            color={"gray.800"}
            lineHeight={1.1}
            fontFamily={"Abril Fatface, cursive"}
            fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
            style={{ letterSpacing: "1.5px" }}
          >
            Get in touch with hungry clients who crave a quick, on-the-go meal
            <Text
              as={"span"}
              bgGradient="linear(to-r, red.400,pink.400)"
              bgClip="text"
            >
              !
            </Text>
          </Heading>
          <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
            Over 3 million daily delivery/ride-sharing drivers are on the road
            every hour, and 1 in 3 of them are searching for their next meal.
            Partnering with hygiWay will direct drivers to your restaurant
            without any cost or revenue split.
          </Text>
        </Stack>
        <Box as={"form"} mt={10}>
          <Stack spacing={4}>
            <Input
              onChange={handleOnChange}
              value={inputs.restaurantName}
              id="restaurantName"
              type="text"
              name="restaurantName"
              placeholder="Restaurant Name"
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
            />
            <Input
              onChange={handleOnChange}
              value={inputs.restaurantAddress}
              id="restaurantAddress"
              type="text"
              name="restaurantAddress"
              placeholder="Restaurant Address"
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
            />
            <Input
              onChange={handleOnChange}
              value={inputs.restaurantPhoneNumber}
              id="restaurantPhoneNumber"
              type="tel"
              name="restaurantPhoneNumber"
              placeholder="Restaurant Phone Number"
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
            />
            <Input
              onChange={handleOnChange}
              value={inputs.managerName}
              id="managerName"
              type="text"
              name="managerName"
              placeholder="Manager First and Last Name"
              bg={"gray.100"}
              border={0}
              color={"gray.500"}
              _placeholder={{
                color: "gray.500",
              }}
            />
            <Flex>
              <Input
                onChange={handleOnChange}
                value={inputs.managerEmail}
                id="managerEmail"
                type="text"
                name="managerEmail"
                placeholder="Email"
                bg={"gray.100"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
                mr={2}
              />
              <Input
                onChange={handleOnChange}
                value={inputs.managerPhone}
                id="managerPhone"
                type="tel"
                name="managerPhone"
                placeholder="Phone"
                bg={"gray.100"}
                border={0}
                color={"gray.500"}
                _placeholder={{
                  color: "gray.500",
                }}
                ml={2}
              />
            </Flex>
            <Text>Is this a franchise?</Text>
            <Stack direction="row">
              <Radio
                id="isFranchise"
                name="isFranchise"
                value="yes"
                isChecked={isFranchise === true}
                onChange={() => {
                  setIsFranchise(true);
                  handleOnChange();
                }}
              >
                Yes
              </Radio>
              <Radio
                id="isFranchise"
                name="isFranchise"
                value="no"
                isChecked={isFranchise === false}
                onChange={() => {
                  setIsFranchise(false);
                  handleOnChange();
                }}
              >
                No
              </Radio>
            </Stack>

            {isFranchise && (
              <>
                <Input
                  onChange={handleOnChange}
                  value={inputs.franchiseName}
                  id="franchiseName"
                  type="text"
                  name="franchiseName"
                  placeholder="Franchise Name"
                  bg={"gray.100"}
                  border={0}
                  color={"gray.500"}
                  _placeholder={{
                    color: "gray.500",
                  }}
                />
                <Input
                  onChange={handleOnChange}
                  value={inputs.franchiseAddress}
                  id="franchiseAddress"
                  type="text"
                  name="franchiseAddress"
                  placeholder="Headquarters Address"
                  bg={"gray.100"}
                  border={0}
                  color={"gray.500"}
                  _placeholder={{
                    color: "gray.500",
                  }}
                />
                <Flex>
                  <Input
                    onChange={handleOnChange}
                    value={inputs.franchiseWebsite}
                    id="franchiseWebsite"
                    type="text"
                    name="franchiseWebsite"
                    placeholder="Franchise Website"
                    bg={"gray.100"}
                    border={0}
                    mr={2}
                    color={"gray.500"}
                    _placeholder={{
                      color: "gray.500",
                    }}
                  />
                  <Input
                    onChange={handleOnChange}
                    value={inputs.franchisePhoneNumber}
                    id="franchisePhoneNumber"
                    type="text"
                    name="franchisePhoneNumber"
                    placeholder="Headquarters Phone"
                    bg={"gray.100"}
                    border={0}
                    ml={2}
                    color={"gray.500"}
                    _placeholder={{
                      color: "gray.500",
                    }}
                  />
                </Flex>
                <Input
                  onChange={handleOnChange}
                  value={inputs.numLocations}
                  id="numLocations"
                  type="number"
                  name="numLocations"
                  placeholder="Total Number of Franchise Locations"
                  bg={"gray.100"}
                  border={0}
                  color={"gray.500"}
                  _placeholder={{
                    color: "gray.500",
                  }}
                />
              </>
            )}
          </Stack>
          <Button
            fontFamily={"heading"}
            mt={8}
            onClick={handleOnSubmit}
            w={"full"}
            bgGradient="linear(to-r, red.400,pink.400)"
            color={"white"}
            _hover={{
              bgGradient: "linear(to-r, red.400,pink.400)",
              boxShadow: "xl",
            }}
          >
            Submit
          </Button>
        </Box>
        form
      </Stack>
    </>
  );
}

import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  Flex,
  useBreakpointValue,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { ReactNode } from "react";

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue("blue.50", "blue.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        ></Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"Ubuntu, sans-serif"}
            fontSize={"xl"}
            color={useColorModeValue("gray.800", "white")}
            bgGradient="linear(to-r, blue.800,blue.500)"
            bgClip="text"
            mr={1}
          >
            hygiWay
          </Text>
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "right" })}
            fontFamily={"Abril Fatface, cursive"}
            fontSize={"xl"}
            color={useColorModeValue("gray.800", "white")}
            bgGradient="linear(to-r, blue.400,blue.200)"
            bgClip="text"
          >
            Partners
          </Text>
        </Flex>
        <Text>© 2023 hygiWay. All rights reserved</Text>
      </Container>
    </Box>
  );
}

import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";

export default function Header() {
  return (
    <Box>
      <Flex
        bg={useColorModeValue("blue.50", "blue.900")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        align={"center"}
        alignItems={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        ></Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "left" })}
            fontFamily={"Ubuntu, sans-serif"}
            fontSize={"2xl"}
            color={useColorModeValue("gray.800", "white")}
            bgGradient="linear(to-r, blue.800,blue.500)"
            bgClip="text"
            mr={1}
          >hygiWay
          </Text>
          <Text
            textAlign={useBreakpointValue({ base: "center", md: "right" })}
            fontFamily={"Abril Fatface, cursive"}
            fontSize={"2xl"}
            color={useColorModeValue("gray.800", "white")}
            bgGradient="linear(to-r, blue.400,blue.200)"
            bgClip="text"
          >Partners
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

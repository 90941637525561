import './App.css';
import Dashboard from "./Dashboard"
import Header from "./Header"
import Footer from "./Footer"
import Form from "./Form"
import React, { useState } from "react";

import {
  ChakraProvider,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

function App() {
  const [authenticated, setAuthenticated] = useState(true);
  const [password, setPassword] = useState("");
  
  const handleOnChange = (e) => {
    setPassword(e.target.value);
  } 
  
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) { // check if Enter key was pressed
      handleLogin();
    }
  }
  
  const handleLogin = () => {
    if (password === 'keepitclean123'){
      setAuthenticated(true)
    } else {
      alert('Wrong password, try again.')
    }
  }
  
  return (
    <ChakraProvider>
      {authenticated ?
        <Box>
          <Header />
          <Dashboard />
          <Footer />
        </Box> : 
        <Flex
          minH={'100vh'}
          align={'center'}
          justify={'center'}
          //bg={useColorModeValue('gray.50', 'gray.800')}
        >
          <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
            <Stack align={'center'}>
              <Heading fontSize={'4xl'}>hygiWay Partners Admin</Heading>
            </Stack>
            <Box
              rounded={'lg'}
              //bg={useColorModeValue('white', 'gray.700')}
              boxShadow={'lg'}
              p={8}
            >
              <Stack spacing={4}>
                <FormControl id="password">
                  <FormLabel>Password</FormLabel>
                  <Input type="password" onChange={handleOnChange} onKeyDown={handleKeyDown}/>
                </FormControl>
                <Stack spacing={10}>
                  <Button
                    bg={'blue.400'}
                    color={'white'}
                    onClick={handleLogin}
                    _hover={{
                      bg: 'blue.500',
                    }}>
                    Sign in
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      }
    </ChakraProvider>
  )
}

export default App;

import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  Icon,
  Box,
  Input,
  Button,
  useColorModeValue,
  chakra,
  Stat,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import {
  IoAnalyticsSharp,
  IoMegaphoneOutline,
  IoHappyOutline,
  IoFastFoodOutline,
  IoLogoBitcoin,
  IoSearchSharp,
} from "react-icons/io5";
import Form from "./Form";

const Feature = ({ text, icon, iconBg }) => {
  return (
    <Stack direction={"row"} align={"center"}>
      <Flex
        w={8}
        h={8}
        align={"center"}
        justify={"center"}
        rounded={"full"}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

const StatsCard = ({ title, stat }) => {
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={"5"}
      shadow={"xl"}
      //border={"2px solid #63b3ed"}
      rounded={"lg"}
      bg={"gray.50"}
    >
      <StatLabel color={"blue.400"} fontWeight={"medium"} isTruncated>
        {title}
      </StatLabel>
      <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
        {stat}
      </StatNumber>
    </Stat>
  );
};

export default function Dashboard() {
  return (
    <Container maxW={"5xl"} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={"uppercase"}
            color={"blue.400"}
            fontWeight={600}
            fontSize={"sm"}
            bg={useColorModeValue("blue.50", "blue.900")}
            p={2}
            alignSelf={"flex-start"}
            border={"2px solid #63b3ed"}
            rounded={"md"}
          >
            Get Started
          </Text>
          <Heading
            color={"gray.800"}
            lineHeight={1.1}
            fontFamily={"Abril Fatface, cursive"}
            fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
            style={{ letterSpacing: "1.5px" }}
          >
            Become a hygiWay QSR Partner:
          </Heading>
          <Text color={"gray.500"} fontSize={"lg"}>
            Partner with a network that has over 3 million hungry customers that
            are searching for a quick meal, daily
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue("gray.100", "gray.700")}
              />
            }
          >
            <Feature
              icon={
                <Icon as={IoMegaphoneOutline} color={"green.500"} w={5} h={5} />
              }
              iconBg={useColorModeValue("green.100", "green.900")}
              text={
                <>
                  Promote your Quick Service Restaurant at <b>no</b> cost
                </>
              }
            />
            <Feature
              icon={
                <Icon as={IoFastFoodOutline} color={"yellow.500"} w={5} h={5} />
              }
              iconBg={useColorModeValue("yellow.100", "yellow.900")}
              text={
                <>
                  Connect directly with a hungry <b>audience</b>, searching for
                  a quick meal
                </>
              }
            />
            <Feature
              icon={
                <Icon as={IoHappyOutline} color={"purple.500"} w={5} h={5} />
              }
              iconBg={useColorModeValue("purple.100", "purple.900")}
              text={"No hassle campaigns or self-service tools"}
            />
          </Stack>
          <Box maxW="7xl" mx={"auto"} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              spacing={{ base: 5, lg: 8 }}
            >
              <StatsCard title={"Tap into"} stat={"3,000,000+ drivers"} />
              <StatsCard title={"Where"} stat={"1 in 3 need a quick meal"} />
              <StatsCard
                title={"With"}
                stat={"No revenue sharing or profit-split"}
              />
              <StatsCard
                title={"Providing"}
                stat={"Relief to over-worked drivers"}
              />
            </SimpleGrid>
          </Box>
        </Stack>
        <Flex>
          <Form />
        </Flex>
      </SimpleGrid>
      <br></br>
      <br></br>
      {/*<Flex
       bg={"gray.50"}
       rounded={"xl"}
       //border={"2px solid #63b3ed"}
       p={{ base: 4, sm: 6, md: 8 }}
       spacing={{ base: 8 }}
       shadow={"xl"}
      minH={'70vh'}
            > Video here.</Flex>*/}
    </Container>
  );
}
